import React, { useRef, useState, useCallback, useEffect } from 'react';
import './App.css';

function App() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showDownload, setShowDownload] = useState(false);

  // Функция для перехода в полноэкранный режим
  const enterFullScreen = (element) => {
    try {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE/Edge */
        element.msRequestFullscreen();
      }
    } catch (error) {
      console.error("Failed to enter fullscreen:", error);
    }
  };

  // Функция для выхода из полноэкранного режима
  const exitFullScreen = () => {
    try {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    } catch (error) {
      console.error("Failed to exit fullscreen:", error);
    }
  };

  const handlePlayVideo = () => {
    if (videoRef.current) {
      const videoElement = videoRef.current;

      // Запрашиваем полноэкранный режим и воспроизводим видео
      enterFullScreen(videoElement);
      videoElement.play();
      setIsPlaying(true);
      setShowDownload(true); // Показываем кнопку загрузки
    }
  };

  const handleVideoEnded = () => {
    setIsPlaying(false);
    exitFullScreen();
    setShowDownload(false); // Прячем кнопку загрузки после окончания видео
  };

  // Функция обработки изменения полноэкранного режима
  const handleFullScreenChange = useCallback(() => {
    if (isPlaying && !isFullScreen()) {
      // Если полноэкранный режим был отключен, пробуем снова
      if (videoRef.current) {
        enterFullScreen(videoRef.current);
      }
    }
  }, [isPlaying]);

  // Функция проверки полноэкранного режима
  const isFullScreen = () => {
    return !!(
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    );
  };

  // Используем useEffect для добавления и удаления обработчиков событий
  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('msfullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('msfullscreenchange', handleFullScreenChange);
    };
  }, [handleFullScreenChange]);

  return (
    <div className="App">
      {!isPlaying && (
        <button className="play-button" onClick={handlePlayVideo}>
          ХОЧУ
        </button>
      )}
      <video ref={videoRef} className="video" src="/video.mp4" onEnded={handleVideoEnded} />
      {showDownload && (
        <a href="/video.mp4" download="video.mp4" className="download-button">
          Скачать видео
        </a>
      )}
    </div>
  );
}

export default App;
